import { AccountType } from '../models/Account/Account.model'

export const ROLES: { code: string; label: string }[] = [
    {
        code: AccountType.SuperAdmin,
        label: 'Суперадмин'
    },
    {
        code: AccountType.Admin,
        label: 'Админ'
    },
    {
        code: AccountType.Doctor,
        label: 'Врач'
    },
    {
        code: AccountType.Patient,
        label: 'Пациент'
    },
    {
        code: AccountType.MedWorker,
        label: 'Средний мед.персонал'
    }
]
