import { type IObservableArray, action, computed, makeObservable, observable } from 'mobx'
import { type Observable, firstValueFrom, mergeMap, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'

import { type OrganizationDto, type Telemed } from '../api/telemed'
import { ROLES } from '../constants/roles'
import { AccountModel, AccountType } from '../models/Account/Account.model'
import { type ProfileInfoUser } from '../ui/User/ProfileInfo/ProfileInfo.models'

import { type AccountService, type TAccountServiceFactory, accountServiceFactory } from './account-service'
import { type TIApplicationStore } from './local-store'

export class AuthService {
    public readonly accounts: IObservableArray<AccountModel>
    public activeAccountService: AccountService | null
    public allOrganizations: OrganizationDto[] = []

    protected readonly factory: TAccountServiceFactory

    get profileInfo(): ProfileInfoUser | null {
        return this.activeAccount
            ? {
                  lastName: this.activeAccount.lastName,
                  firstName: this.activeAccount.firstName,
                  middleName: this.activeAccount.middleName,
                  organization: this.orgAuthUser,
                  role: ROLES.find((item) => item.code === this.activeAccount!.role)
              }
            : null
    }

    get orgAuthUser(): OrganizationDto | undefined {
        return this.allOrganizations.find((item) => item.id === this.activeAccount?.orgId)
    }

    constructor(
        protected telemed: Telemed,
        protected applicationStore: TIApplicationStore,
        accounts: AccountModel[],
        public activeAccount: AccountModel | null
    ) {
        this.accounts = observable(accounts, { deep: false })
        this.factory = accountServiceFactory(telemed)
        this.activeAccountService = activeAccount ? this.factory(activeAccount) : null
        makeObservable(this, {
            activeAccount: observable.ref,
            allOrganizations: observable.ref,
            profileInfo: computed,
            orgAuthUser: computed,
            chooseAccount: action.bound,
            onSuccessLogin: action.bound,
            updateAllOrganizations: action.bound,
            getOrganization: action.bound
        })
        this.telemed.setUserContext(this.activeAccount)
        this.updateAllOrganizations()
    }

    login(fields: Record<string, string>, tokenAuth?: boolean): Observable<AccountModel> {
        return tokenAuth
            ? this.loginToken(fields).pipe(tap(this.onSuccessLogin))
            : this.loginAccount(fields).pipe(tap(this.onSuccessLogin))
    }

    updateAllOrganizations() {
        if (this.activeAccount) {
            this.telemed
                .getOrganizations()
                .pipe(
                    tap((resp) => {
                        this.allOrganizations = resp.value
                    })
                )
                .subscribe()
        }
    }

    getOrganization(orgId: number | null): OrganizationDto | undefined {
        return this.allOrganizations.find((item) => item.id === orgId)
    }

    onSuccessLogin(account: AccountModel) {
        if (account.role !== AccountType.MedWorker && account.role !== AccountType.Patient) {
            this.telemed.setUserContext(account)
            this.telemed
                .getOrganizations()
                .pipe(
                    map((resp) => {
                        this.allOrganizations = resp.value

                        if (this.activeAccount) {
                            this.accounts.clear()

                            this.applicationStore.delete('accounts', this.activeAccount.userId)
                        }

                        const current = this.accounts.find((it) => it.userId === account.userId)
                        if (current) {
                            Object.assign(current, account)
                        } else {
                            this.accounts.unshift(account)
                            this.chooseAccount(account)

                            try {
                                this.applicationStore.set('accounts', account)
                            } catch (e) {}
                        }
                    })
                )
                .subscribe()
        }
    }

    chooseAccount(account: AccountModel | null) {
        if (this.activeAccount === account) {
            return
        }
        this.activeAccount = account
        if (account) {
            this.accounts.clear()
            this.accounts.unshift(account)
            this.telemed.setUserContext(account)
            this.activeAccountService = account ? this.factory(account) : null

            try {
                this.applicationStore.set('accounts', account)
            } catch (e) {}
        }
    }

    logout(account: AccountModel, skipServerLogout = false, useNextAccount = true): void {
        this.accounts.clear()
        this.applicationStore.delete('accounts', account.userId)
        this.chooseAccount(this.accounts[0] && useNextAccount ? this.accounts[0] : null)
        localStorage.clear()

        if (!skipServerLogout) {
            void firstValueFrom(
                this.telemed.logout().pipe(
                    map(() => this.telemed.setUserContext(null)),
                    catchError(() => of(undefined))
                )
            )
        } else {
            this.telemed.setUserContext(null)
        }
    }

    protected loginToken(fields: Record<string, string>) {
        return this.telemed.integrationTempLogin().pipe(
            mergeMap((resp) =>
                this.telemed.integrationLogin(resp.token, {
                    externalId: fields.externalId,
                    organizationId: fields.organizationId
                })
            ),
            map((resp) =>
                AccountModel.fromObject({
                    userId: resp.id,
                    role: AccountType.Doctor,
                    accessToken: resp.token,
                    firstName: AccountType.Doctor,
                    lastName: '',
                    email: '',
                    phone: '',
                    orgId: null,
                    organization: undefined,
                    avatar: '',
                    middleName: '',
                    externalId: resp.externalId
                })
            )
        )
    }

    protected loginAccount(fields: Record<string, string>): Observable<AccountModel> {
        return this.telemed
            .login({
                email: fields.email,
                password: fields.password
            })
            .pipe(
                map((resp) =>
                    AccountModel.fromObject({
                        userId: resp.userId,
                        role: resp.role,
                        accessToken: resp.accessToken,
                        firstName: resp.firstName,
                        lastName: resp.lastName,
                        email: resp.email,
                        phone: resp.phone,
                        orgId: resp.orgId,
                        organization: undefined,
                        avatar: resp.avatar,
                        middleName: resp.middleName,
                        externalId: null
                    })
                )
            )
    }
}
