import {
    BacIcon,
    BloodPressureIcon,
    Carbo,
    Cholesterol,
    EkgIcon,
    Glukoze,
    Go,
    Hemoglobin,
    Insulin,
    Lungs,
    Pulse,
    Sleep,
    SpO2,
    Statoskop,
    Temperature,
    Urine,
    WeightIcon
} from '../../../assets'
import { type ITypeMeasurements } from '../models/Measurements.model'

export const TYPES_MEASUREMENTS: ITypeMeasurements[] = [
    {
        code: 'blood_pressure',
        label: 'Артериальное давление',
        unitType: 'мм рт. ст.',
        typeField: 'integer',
        min: 20,
        max: 280,
        showGraph: true,
        maxAxis: 300,
        icon: <BloodPressureIcon className='types-measurements__icon' />
    },
    {
        code: 'pulse',
        label: 'Пульс',
        typeField: 'integer',
        unitType: 'уд/мин',
        showGraph: true,
        min: 40,
        max: 200,
        maxAxis: 200,
        icon: <Pulse className='types-measurements__icon' />
    },
    {
        code: 'hemoglobin',
        label: 'Гемоглобин',
        typeField: 'float',
        unitType: 'ммоль/л',
        showGraph: true,
        min: 0,
        max: 15,
        maxAxis: 200,
        icon: <Hemoglobin className='types-measurements__icon' />
    },
    {
        code: 'cholesterol',
        label: 'Холестерин',
        typeField: 'float',
        showGraph: true,
        min: 0,
        max: 15,
        maxAxis: 200,
        unitType: 'ммоль/л',
        icon: <Cholesterol className='types-measurements__icon' />
    },
    {
        code: 'glucose',
        label: 'Уровень глюкозы',
        typeField: 'float',
        unitType: 'ммоль/л',
        showGraph: true,
        min: 0,
        max: 35,
        maxAxis: 35,
        icon: <Glukoze className='types-measurements__icon' />
    },
    {
        code: 'insulin',
        label: 'Инсулин',
        typeField: 'integer',
        showGraph: false,
        maxAxis: 100,
        unitType: 'ЕД',
        icon: <Insulin className='types-measurements__icon' />
    },
    {
        code: 'carbs',
        label: 'Углеводы',
        typeField: 'integer',
        showGraph: false,
        unitType: 'ХЕ',
        maxAxis: 30,
        icon: <Carbo className='types-measurements__icon' />
    },
    {
        code: 'temperature',
        label: 'Температура',
        showGraph: true,
        typeField: 'float',
        min: 30,
        max: 45,
        unitType: 'С°',
        maxAxis: 50,
        icon: <Temperature className='types-measurements__icon' />
    },
    {
        code: 'weight',
        label: 'Вес',
        typeField: 'float',
        showGraph: true,
        unitType: 'кг',
        min: 0.1,
        max: 150,
        maxAxis: 150,
        icon: <WeightIcon className='types-measurements__icon' />
    },
    {
        code: 'spo2',
        label: 'Сатурация',
        showGraph: true,
        typeField: 'integer',
        min: 0,
        max: 100,
        unitType: '%',
        maxAxis: 100,
        icon: <SpO2 className='types-measurements__icon' />
    },
    {
        code: 'auscult',
        label: 'Аускультация',
        showGraph: false,
        typeField: 'integer',
        unitType: 'мм рт. ст.',
        maxAxis: 150,
        icon: <Statoskop className='types-measurements__icon' />
    },
    {
        code: 'steps',
        label: 'Физическая активность',
        typeField: 'integer',
        showGraph: true,
        min: 0,
        max: 999999,
        unitType: 'Шаги',
        maxAxis: 10000,
        icon: <Go className='types-measurements__icon' />
    },
    {
        code: 'sleep',
        label: 'Сон',
        typeField: 'integer',
        showGraph: true,
        min: 0,
        max: 24,
        unitType: 'Время',
        maxAxis: 12,
        icon: <Sleep className='types-measurements__icon' />
    },
    {
        code: 'spirometry',
        label: 'Спирометрия',
        typeField: 'float',
        showGraph: true,
        min: 0,
        max: 16,
        maxAxis: 16,
        unitType: 'ФЖЕЛ (л)',
        icon: <Lungs className='types-measurements__icon' />
    },
    {
        code: 'urine',
        label: 'Анализ мочи',
        showGraph: false,
        typeField: 'integer',
        unitType: 'мкмоль/л',
        icon: <Urine className='types-measurements__icon' />
    },
    {
        code: 'ecg',
        label: 'ЭКГ',
        showGraph: false,
        typeField: 'integer',
        unitType: '',
        icon: <EkgIcon className='types-measurements__icon' />
    },
    {
        code: 'bac',
        label: 'BAC',
        showGraph: false,
        min: 0.0,
        max: 2.0,
        typeField: 'float',
        unitType: 'мг/л',
        icon: <BacIcon className='types-measurements__icon' />
    }
]
