import {
    DownNormIcon,
    FilterDownNormIcon,
    FilterNoNormIcon,
    FilterNormIcon,
    FilterUpNormIcon,
    NoRangeNormIcon,
    NormIcon,
    UpNormIcon
} from '../../../assets'
import { type INorm } from '../models/Measurements.model'

export const NORMS: INorm[] = [
    {
        code: 'NoNorm',
        label: 'Без нормы',
        icon: <NoRangeNormIcon />,
        iconFilter: <FilterNoNormIcon />
    },
    {
        code: 'Norm',
        label: 'Норма',
        icon: <NormIcon />,
        iconFilter: <FilterNormIcon />
    },
    {
        code: 'Above',
        label: 'Выше нормы',
        icon: <UpNormIcon />,
        iconFilter: <FilterUpNormIcon style={{ color: '#F44336' }} />
    },
    {
        code: 'Below',
        label: 'Ниже нормы',
        icon: <DownNormIcon />,
        iconFilter: <FilterDownNormIcon style={{ color: '#F1C636' }} />
    },
    {
        code: 'BelowAttention',
        label: 'Ниже нормы',
        icon: <DownNormIcon />,
        iconFilter: <FilterDownNormIcon style={{ color: '#de06ff' }} />
    },
    {
        code: 'BelowNotCritical',
        label: 'Ниже нормы',
        icon: <DownNormIcon />,
        iconFilter: <FilterDownNormIcon style={{ color: '#06b2ff' }} />
    },
    {
        code: 'BelowCritical',
        label: 'Ниже нормы',
        icon: <DownNormIcon />,
        iconFilter: <FilterDownNormIcon style={{ color: '#0066ff' }} />
    },
    {
        code: 'AboveAttention',
        label: 'Выше нормы',
        icon: <UpNormIcon />,
        iconFilter: <FilterUpNormIcon style={{ color: '#ff8c06' }} />
    },
    {
        code: 'AboveNotCritical',
        label: 'Выше нормы',
        icon: <UpNormIcon />,
        iconFilter: <FilterUpNormIcon style={{ color: '#ebeb0e' }} />
    },
    {
        code: 'AboveCritical',
        label: 'Выше нормы',
        icon: <UpNormIcon />,
        iconFilter: <FilterUpNormIcon style={{ color: '#F44336' }} />
    }
]
